import { createStore } from 'vuex'
import jwt_decode from "jwt-decode";

export default createStore({
  state: {
    loadCount: 0,
    isLoading:false,
    isAuthenticated: false,
    billiapi: null,
    token: null,
    token_decoded: null,
  },
  getters: {
    billiapi(state) {
      return state.billiapi;
    },    
    isLoading(state) {
      return state.isLoading;
    },
    isAuthenticated(state){
      return state.isAuthenticated;
    },
    token(state){
      return state.token;
    },
    token_decoded(state, getters){
      // return state.token_decoded;
      if(getters.token != undefined && getters.token != null && getters.token != "undefined")
        return jwt_decode(getters.token);
      else
        return null;
    }
  },
  mutations: {
    isLoading(state, value) {
      state.isLoading = value;
      if (value == true)
        state.loadCount++;
      else
        state.loadCount--;
    },
    isAuthenticated(state, value){
      state.isAuthenticated = value;
    },
    billiapi(state, value){
      state.billiapi = value;
    },
    token(state, value){
      state.token = value;
    },
    token_decoded(state, value){
      state.token_decoded = value;
    }
  },
  actions: {
    isLoading(context, isLoading) {
      context.commit("isLoading", isLoading);
    },
    isAuthenticated(context, isAuthenticated){
      context.commit("isAuthenticated", isAuthenticated)
    },
    billiapi(context, billiapi){
      context.commit("billiapi", billiapi)
    },
    token(context, token){
      context.commit("token", token)
    },
    token_decoded(context, token_decoded){
      context.commit("token_decoded", token_decoded)
    }
  },
  modules: {
  }
})
