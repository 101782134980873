import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import store from '../store/index'

import AlbumPage from '../pages/AlbumPage.vue'
import AlbumDetailPage from '../pages/AlbumDetail.vue'

import MediaDetailPage from '../pages/MediaDetail.vue'
import SearchPage from '../pages/SearchPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
      {
        path: '',
        component: AlbumPage,
        name: 'albumlist'
      },
      {
        path:'album/:id',
        component: AlbumDetailPage,
        name: 'albumdetail'
      },
      {
        path:'media/:id',
        component: MediaDetailPage,
        name: 'mediadetail'
      },
      {
        path:'search',
        component: SearchPage,
        name: 'search'
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isAuthenticated)
  {
    next({
      name: 'login'
    })
  }
  else{
    next();
  }
});


export default router
