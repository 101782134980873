<template>
  <div class="img-wrapper">
    <img :src="data.url" @load="imgloaded" v-show="isloaded">
   

    <div class="placeholder" v-if="!isloaded"> <font-awesome-icon icon="fa-solid fa-image" class="phicon fa-2x"/></div>
    <div class="filename">{{data.filename}}</div>
  </div>
</template>

<script>
export default {
props:['data'],
data(){
   return{
    isloaded:false,
   }
},
methods:{
  imgloaded(){
    this.isloaded = true;
  }
}
}
</script>

<style scoped>
  .img-wrapper{
    width:300px;
    height:200px;
    
    
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border:3px solid transparent;
    transition: all 0.2s ease;
  }
  .img-wrapper .placeholder{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .phicon{
    color:#fff;

  }
  .img-wrapper img{
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;

  }
  .img-wrapper:hover{
    border:3px solid rgba(var(--bs-dark-rgb)) ;
    cursor: pointer;
  }
  .filename{
    background-color:rgb(56, 56, 56);
    color:#fff;
    width:100%;
    text-align: center;

  }
</style>