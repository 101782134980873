<template>
    <main class="w-100">
        <Sidebar />

        <div class="container-fluid scrollcontent">
            <router-view></router-view>
        </div>
    </main>


</template>

<script>
import Sidebar from '../components/Sidebar.vue'
export default {
  components:{
    Sidebar
  },
  methods: {
    loader(){
      this.$store.dispatch("isLoading", true);
    }
  },
}
</script>

<style scoped>
.scrollcontent{
  overflow-y: scroll;
}
</style>