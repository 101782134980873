<template>
  
    <LoginBox  class="text-center w-100"/>
  
</template>

<script>
import LoginBox from '../components/LoginBox.vue'
export default {
    components:{
        LoginBox
    }

}
</script>

<style>

</style>