<template>
<div class="tagbox-wrapper">
    <div class="input-group mb-3">
        <input v-model="tagvalue" type="text" class="form-control" placeholder="Tag hinzufügen" @keydown.enter="addTag">
        <button class="btn btn-outline-secondary" type="button" @click="addTag">Speichern</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            tagvalue:''
        }
    },
    methods:{
        async addTag(){
            let data = {};
            data.name = this.tagvalue;

            this.$store.getters.billiapi.post("tag", data).then((res) => {
                this.$emit("addTag", res.data);
                this.tagvalue = "";
            });

        }
    }
}
</script>

<style scoped>
.tagbox-wrapper{
    width:400px;
}
</style>
