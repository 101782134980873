<template>
<div>
    <h1>{{ item.filename }}</h1>
    <button @click="delImg" class="btn btn-secondary"><font-awesome-icon icon="fa-solid fa-trash" /> Bild löschen</button>
    <br />
    <img :src="item.url" style="max-width:500px" />
    <h2>Details:</h2>
    {{ item.filename }}<br />
    <p>Beschreibung: {{ item.description }}</p>
    <p>Likes: {{ item.likes }}</p>

    {{ item.width }} x {{ item.height }}

    <h2>Tags</h2>

    <TagBox @addTag="tagAdded" />


    <div class="tagWrapper">
        <span class="badge text-bg-primary p-2" v-for="tag in item.mediaTagList" :key="tag.tagId">{{ gettagname(tag.tagId) }} <font-awesome-icon @click="deleteTag(tag)" class="delTagIcon ms-2" icon="fa-solid fa-times" /></span>
    </div>



    <!-- <ul>
        <li v-for="tag in item.mediaTagList" :key="tag.tagId">{{ gettagname(tag.tagId) }}</li>
    </ul> -->

</div>
</template>

<script>
import TagBox from '../components/TagBox.vue'
export default {
    components: {
        TagBox
    },
    data() {
        return {
            id: null,
            item: {},
            tags: [],
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        tagAdded(tag) {
            this.item.mediaTagList.push({
                tagId: tag.id,
                name: tag.name
            });
            this.tags.push(tag);
            this.$store.getters.billiapi.put("media/" + this.id, this.item).then((res) => {
                console.log(res);
            });
        },
        deleteTag(tag){
            let index = this.item.mediaTagList.indexOf(tag);
            this.item.mediaTagList.splice(index, 1);
            
            this.$store.getters.billiapi.put("media/" + this.id, this.item).then((res) => {
                this.$swal({
                    icon: 'success',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 1500,
                    title: 'Tag gelöscht!'
                });

            });



        },
        delImg() {
            this.$store.getters.billiapi.delete("media/" + this.id).then((res) => {
                this.$router.back();
            });
        },
        gettagname(id) {
            if (this.tags != undefined && this.tags.length > 0)
                return this.tags.find(f => f.id == id).name;
            else
                return id;
        },
        async load() {
            this.id = this.$route.params.id;

            this.$store.getters.billiapi.get("media/" + this.id).then((res) => {
                console.log(res);
                this.item = res.data;
            });

            this.$store.getters.billiapi.get("tag").then((res) => {
                this.tags = res.data;
            });
        }
    }
}
</script>

<style scoped>
.tagWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25em;
}
.tagWrapper .badge{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.delTagIcon{
    
}
.delTagIcon:hover{
    color:#000;
    cursor:pointer
}

</style>
