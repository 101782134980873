<template>
<div>
    <main class="text-center" >
        <form class="form-signin w-100 m-auto" @submit.prevent="">
            <img class="mb-4" alt="" width="172" src="@/assets/img/billi_logo.png">
            <h1 class="h3 mb-3 fw-normal">Login</h1>

            <div class="form-floating">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" v-model="formdata.email">
                <label for="floatingInput">Email</label>
            </div>
            <div class="form-floating">
                <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="formdata.password">
                <label for="floatingPassword">Passwort</label>
            </div>


            <button class="w-100 btn btn-lg btn-primary" type="submit" @click="dologin">Anmelden</button>

        </form>
    </main>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Login',
    components: {

    },
    data(){
        return{
            formdata:{
                email:'rodlmayr@hotmail.com',
                password:'asdf'
            }
        }
    },
    methods: {
        dologin(){
            this.$store.dispatch("isLoading", true);
            axios.post(process.env.VUE_APP_API + "authentication/login", this.formdata).then((res) => {
                if(res.status == 200){
                    this.$store.dispatch("isAuthenticated", true);
                    this.$store.dispatch("token", res.data.token);
                    window.sessionStorage.setItem("authData", JSON.stringify(res.data));
                    this.$router.push({ name: 'albumlist' });
                }
            });
            

            
        }
    },
}
</script>

<style>
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
</style>
