<template>
<div class="app-wrapper" :class="mobileClass">
    <router-view></router-view>
    <Loader v-if="loading" />
</div>
</template>

<script>
import Loader from './components/LoaderOverlay.vue'
import billiApi from './store/axios-billi.js'

export default {
    components: {
        Loader,

    },
    data() {
        return {
            mobileClass: ''
        }
    },
    created(){
        this.$store.dispatch("billiapi", billiApi);
    },  
    computed: {
        loading() {
            return this.$store.getters.isLoading;
        }
    },
    methods: {
        isLoading() {
            if (this.$store.getters.loadCount > 0 && this.$store.getters.isLoading)
                return true;
            else
                return false;
        }
    },
    mounted() {
        console.log("APP MOUNTED");
        console.log("node ENV:", process.env.NODE_ENV);
        console.log("API:", process.env.VUE_APP_API);

        let authData = window.sessionStorage.getItem("authData");
        if(authData != undefined && authData != null){
            authData = JSON.parse(authData);

            this.$store.dispatch("isAuthenticated", true);
            this.$store.dispatch("token", authData.token);
        }
    }
}
</script>

<style scoped>

</style>
