<template>
<div class="card album-card">
    <img :src="cover" class="card-img-top">
    <div class="card-body">
        <h5 class="card-title">{{data.name}}</h5>
        <div>
            <span class="badge rounded-pill text-bg-secondary">Aktiv: {{aktiv}}</span>
            <span class="badge rounded-pill text-bg-secondary">Fotos: {{data.mediaCount}}</span>
        </div>
        <router-link class="btn btn-primary float-end" :to="'album/' + data.id">
            <font-awesome-icon icon="fa-solid fa-pen" />
        </router-link>

    </div>
</div>
</template>

<script>
export default {
    props: ['data'],
    computed: {
        aktiv() {
            if (this.data.active)
                return "Ja";
            else
                return "Nein";
        },

        cover() {
            if (this.data != null && this.data.coverImage != null)
                return this.data.coverImage;
            else
                return "https://imgs.gem2go.dev/100";
        }

    }
}
</script>

<style scoped>
.album-card {
    width: 250px;
}
.album-card .card-img-top{
    object-fit: cover;
    height:150px;
}
</style>
