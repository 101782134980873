import axios from "axios";
import store from "./index";

const dataIOAxios = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
  }

});

dataIOAxios.interceptors.request.use((config) => {
    store.dispatch('isLoading', true);
    if(store.getters.token != null){
        
      if(store.getters.token != null && store.getters.token != "")
        config.headers["Authorization"] = 'Bearer ' + store.getters.token;
  }
  else
    console.log ("token war null zum zeitpunkt des aufrufs", config);
    
    return config;
}, (error) => {
    store.dispatch('isLoading', false);
    console.log("ERROR:")
    console.error(error);

    return Promise.reject(error);
});

dataIOAxios.interceptors.response.use((response) => {
    store.dispatch('isLoading', false);
    return response;
}, (error) => {
    store.dispatch('isLoading', false);
    console.log("ERROR:")
    console.error(error);
    
    return Promise.reject(error);
});


export default dataIOAxios;
