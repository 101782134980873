<template>
  <h1>Suche</h1>
    <input type="text" v-model="query" />
    <button @click="search">Suche</button>

    <hr>
    

    <div>
        <ul>
            <li v-for="item in list" :key="item.id">
                <img :src="item.url" style="width:100px" />
            </li>
        </ul>
        
    </div>

</template>

<script>
export default {
    data(){
        return{
            query:'',
            list: [],
        }
    },
    methods:{
        async search(){
            this.$store.getters.billiapi("search/" + this.query).then((res) => {
                this.list = res.data;
            })
        }
    }
}
</script>

<style>

</style>