<template>
<h1>Alben</h1>
<button @click="addAlbum" class="btn btn-secondary"><font-awesome-icon icon="fa-solid fa-plus" /> Hinzufügen</button>

<div class="album-wrapper">
    <AlbumBox v-for="album in list" :key="album.id" :data="album" class="album-item" />
</div>


</template>

<script>
import AlbumBox from '../components/AlbumBox.vue'

export default {
    components: {
        AlbumBox
    },
    data() {
        return {
            list: []
        }
    },
    mounted() {
      this.load();
    },
    methods: {
      async load(){
        this.$store.getters.billiapi.get("album").then((res) => {
            this.list = res.data;
        })
      },
        async addAlbum() {
            const { value: newalbumname } = await this.$swal({
                title: 'Album Name',
                input: 'text',
                inputLabel: 'Name',
                inputValue: '',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!'
                    }
                }
            });

            if(newalbumname){
              this.$store.getters.billiapi.post("album", {name: newalbumname}).then((res)=> {
                this.load();
              })

            }
        }
    }
}
</script>

<style scoped>
.album-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.album-item {
    margin-right: 1em;
}
</style>
