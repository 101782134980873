<template>
<div class="loader">
    <div class="loader-center">
        <font-awesome-icon icon="fas fa-spinner" spin />
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 1000;
    position: fixed;
}

.loader-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader svg {
    font-size: 3em;
    color: #fff;
}
</style>
