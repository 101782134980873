<template>

  <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 280px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
      <img class="mb-4" alt="" width="172" src="@/assets/img/billi_logo.png">
    </a>
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <!-- <a href="" class="nav-link active" aria-current="page">
          <font-awesome-icon icon="fa-solid fa-home" class="me-2" />
          Alben
        </a> -->
        <router-link to="/" class="nav-link">
          Alben
        </router-link>
        <ul>
          <li>asdf</li>
        </ul>
      </li>
      <li class="nav-item">
        <router-link to="/search" class="nav-link">
          Suche
        </router-link>
        <!-- <a href="/search" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg>
          search
        </a> -->
      </li>
      <!-- <li>
        <a href="#" class="nav-link text-white">
         
          Orders
        </a>
      </li>
      <li>
        <a href="#" class="nav-link text-white">
          
          Products
        </a>
      </li>
      <li>
        <a href="#" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16"><use xlink:href="#people-circle"></use></svg>
          Customers
        </a>
      </li> -->
    </ul>
    <hr>
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://imgs.gem2go.dev/100" alt="" class="rounded-circle me-2" width="32" height="32">
        <strong>{{username}}</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <!-- <li><a class="dropdown-item" href="#">New project...</a></li> -->
        <!-- <li><a class="dropdown-item" href="#">Settings</a></li> -->
        <li><a class="dropdown-item" href="#">Profil</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" @click="logout">Abmelden</a></li>
      </ul>
    </div>
  </div>


</template>

<script>
export default {
  name: 'Sidebar',

  mounted(){
    console.log(this.$store.getters.token_decoded);
  },
  computed:{
    username(){
      return this.$store.getters.token_decoded.login;
    }
  },
  methods:{
    logout(){
      this.$store.dispatch("isAuthenticated", false);
      this.$store.dispatch("token", "");
      window.sessionStorage.removeItem("authData");
      this.$router.push({ name: 'login' });
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
