<template>
<h1>Album: {{ item.name }}</h1>


<button @click="delAlbum" class="btn btn-secondary"><font-awesome-icon icon="fa-solid fa-trash" /> Album Löschen</button>

<div class="medialist">
    <ImageBox v-for="img in item.mediaList" :key="img.id" :data="img" @click="detail(img)" />
</div>

<div>
    upload
    <UploadBox :albumId="id" @finished="load" />

</div>
</template>

<script>
import ImageBox from '../components/ImageBox.vue'
import UploadBox from '../components/UploadBox.vue'

export default {
    components: {
        ImageBox,
        UploadBox
    },
    data() {
        return {
            id: null,
            item: {}
        }
    },
    mounted() {
        this.id = this.$route.params.id;

        this.load();
    },
    methods: {
        async load() {
            this.$store.getters.billiapi.get("album/" + this.id).then((res) => {
                console.log(res);
                this.item = res.data;
            });
        },
        delAlbum() {
            this.$store.getters.billiapi.delete("album/" + this.id).then((res) => {
                this.$router.push({
                    name: "albumlist"
                });
            });

        },
        detail(img) {
            this.$router.push({
                name: "mediadetail",
                params: {
                    id: img.id
                }
            });
        }
    }

}
</script>

<style scoped>
.medialist {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 1em;

}
</style>
