<template>
<div class="upload-wrapper" @click="openUpload" @drop.prevent="handleFileDrop($event)" @dragover="dragover" @dragleave="dragleave" :class="{'hasFile' : hasFile}">

    <input type="file" multiple ref="fileupload" style="visibility:hidden; display:none" @change="handleFileUpload($event.target.files)" />
    {{ progress }}
</div>
</template>

<script>
export default {
    props: ['albumId'],
    data(){
      return{
        progress:0,
      }
    },
    methods: {
        dragover(event) {
            event.preventDefault();
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        dragleave(event) {
            event.currentTarget.classList.remove('bg-green-300');
        },

        handleFileDrop(event) {
            event.preventDefault();
            event.stopPropagation();

            this.handleFileUpload(event.dataTransfer.files);
            event.currentTarget.classList.remove('bg-green-300');
        },
        openUpload() {
            this.$refs.fileupload.click();
        },
        async handleFileUpload(files) {

            const datafiles = files;
            let headers = {
                'Content-Type': 'multipart/form-data'
            };

            let fd = new FormData();
            for (let i = 0; i < datafiles.length; i++) {
                
                fd.append('files', datafiles[i]);

                
            }
            const retval = await this.$store.getters.billiapi.post("upload?albumId=" + this.albumId, fd, {
                    headers: headers,
                    onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            this.progress = percentCompleted;
                        }
                })
              console.log(retval);
            

            // for (let i = 0; i < datafiles.length; i++) {
            //     let formData = new FormData();
            //     formData.append('file', datafiles[i]);

            //     let headers = {
            //         'Content-Type': 'multipart/form-data'
            //     };

            //     let elem = retval.data.data;
            //     elem.url = URL.createObjectURL(datafiles[i]);
            //     elem.hatRecht = true;
            //     elem.exportright = true;
            //     elem.typeuri = this.typ;
            //     elem.checked = false;
            //     elem.order = 3;

            //     if (this.doAnalysis) {
            //         elem = await this.startAnalyse(elem);
            //     }

            //     this.localData.push(elem);
            //     this.validate();

            //     if (i == 0) {
            //         this.currentElement = elem;
            //         this.showModal = true;
            //     }

            // }

            
            this.$emit('finished');
        },
    }
}
</script>

<style scoped>
.upload-wrapper {
    width: 250px;
    border: 2px dashed #b5b5c3;
    height: 250px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-wrapper.hasFile {
    border-color: green;
}

.bg-gray-100 {
    background-color: lightgray;
}

.bg-green-300 {
    background-color: mediumaquamarine;
}
</style>
